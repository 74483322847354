<!--
 * @Author: dongjia
 * @Date: 2021-06-25 16:58:26
 * @LastEditTime: 2021-08-11 18:31:22
 * @LastEditors: aleaner
 * @Description: 企微设置页面
 * @FilePath: \saas-admin-vue\src\modules\wx-company\views\wx-company-config.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="term-config">
    <div class="list-content" v-loading="loading">
      <el-form
        v-if="config"
        :rules="rules"
        size="medium"
        :model="config"
        label-position="right"
        label-width="125px"
        class="medium-form"
        ref="config"
      >
        <div v-if="false" class="modular">
          <p class="modular-title">绑定企微</p>
          <div class="modular-content" style="margin-left: 11px">
            <el-form-item label="企业 ID：" prop="corp_id">
              <el-input v-model="config.corp_id" placeholder="请输入" />
              <div class="info">
                <i class="el-icon-info" />
                <span
                  >配置后将与企业微信进行绑定，<a
                    href="https://www.yuque.com/docs/share/a63587da-f4ef-4ee7-a7df-e7a936a84863?#"
                    target="_blank"
                    class="cursor-pointer"
                    >查看企业 ID 获取方式指引</a
                  ></span
                >
              </div>
            </el-form-item>
          </div>
        </div>
        <div v-if="false" class="modular">
          <p class="modular-title">绑定客户联系插件</p>
          <div class="modular-content" style="margin-left: 11px">
            <el-form-item
              label="客户联系 Secret："
              prop="is_set_contact_corp_secret"
            >
              <template v-if="config.is_set_contact_corp_secret">
                <span style="margin-right: 10px">已填写</span
                ><el-button
                  type="text"
                  @click="showInputDialog('修改信息', 'inputDialog')"
                  >修改</el-button
                >
              </template>
              <el-button
                v-else
                type="text"
                @click="showInputDialog('填写信息', 'inputDialog')"
                >填写</el-button
              >

              <div class="info">
                <i class="el-icon-info" />
                <span
                  >配置后将于企业微信-客户联系插件进行绑定，<a
                    href="https://www.yuque.com/docs/share/a63587da-f4ef-4ee7-a7df-e7a936a84863?#FgVtj"
                    target="_blank"
                    class="cursor-pointer"
                    >查看客户联系 Secret 获取方式指引</a
                  ></span
                >
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <p class="modular-title">群机器人设置</p>
          <div class="modular-content" style="margin-left: 11px">
            <el-button type="primary" @click="addRobotDialog">添加</el-button>
            <div class="tips">
              <i class="el-icon-info" />
              <el-link
                target="_brank"
                href="https://open.work.weixin.qq.com/help2/pc/14931?person_id=1&is_tencent="
                type="primary"
                :underline="false"
                >群机器人设置指引</el-link
              >
            </div>
            <div class="tab-content">
              <el-table
                :data="config.robots"
                class="thead-light"
                ref="multipleTable"
                style="width: 100%"
                stripe
              >
                <template v-for="(th, index) in thead">
                  <el-table-column
                    :prop="th.prop"
                    :label="th.label"
                    :width="th.width || ''"
                    :key="index"
                  >
                    <template slot-scope="{ row }">
                      <template v-if="th.prop === 'admin_user_messages'">
                        <el-popover
                          placement="right"
                          width="300"
                          trigger="click"
                        >
                          <el-table
                            :data="row.admin_user_messages"
                            style="width: 100%"
                            max-height="200"
                          >
                            <el-table-column
                              property="title"
                              label="消息类型"
                            ></el-table-column>
                          </el-table>
                          <el-button type="text" slot="reference"
                            >查看</el-button
                          >
                        </el-popover>
                      </template>
                      <p v-else style="white-space: pre-wrap">
                        {{ row[th.prop] | placeholder }}
                      </p>
                    </template>
                  </el-table-column>
                </template>
                <!-- 操作 -->
                <el-table-column label="操作" :width="120" fixed="right">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="EditRow(scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      size="small"
                      @click="DeleteRow(scope.$index)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!--        <div class="modular">-->
        <!--          <p class="modular-title">绑定企微应用</p>-->
        <!--          <div class="modular-content" style="margin-left: 11px;">-->
        <!--            <el-form-item label="应用 ID：" prop="app_agentid">-->
        <!--              <el-input v-model="config.app_agentid" placeholder="请输入" />-->
        <!--&lt;!&ndash;              <div class="info">&ndash;&gt;-->
        <!--&lt;!&ndash;                <i class="el-icon-info" />&ndash;&gt;-->
        <!--&lt;!&ndash;                <span><a href="https://www.yuque.com/docs/share/a63587da-f4ef-4ee7-a7df-e7a936a84863?#"&ndash;&gt;-->
        <!--&lt;!&ndash;                    target="_blank" class=" cursor-pointer">查看应用 ID 获取方式指引</a></span>&ndash;&gt;-->
        <!--&lt;!&ndash;              </div>&ndash;&gt;-->
        <!--            </el-form-item>-->
        <!--            <el-form-item label="应用 Secret：" prop="is_set_app_secret">-->
        <!--              <template v-if="config.is_set_app_secret">-->
        <!--                <span style="margin-right: 10px;">已填写</span><el-button type="text" @click="showInputDialog('修改信息', 'appDialog')">修改</el-button>-->
        <!--              </template>-->
        <!--              <el-button v-else type="text" @click="showInputDialog('填写信息', 'appDialog')">填写</el-button>-->
        <!--&lt;!&ndash;              <div class="info">&ndash;&gt;-->
        <!--&lt;!&ndash;                <i class="el-icon-info" />&ndash;&gt;-->
        <!--&lt;!&ndash;                <span><a href="https://www.yuque.com/docs/share/a63587da-f4ef-4ee7-a7df-e7a936a84863?#"&ndash;&gt;-->
        <!--&lt;!&ndash;                    target="_blank" class=" cursor-pointer">查看客户联系 Secret 获取方式指引</a></span>&ndash;&gt;-->
        <!--&lt;!&ndash;              </div>&ndash;&gt;-->
        <!--            </el-form-item>-->
        <!--          </div>-->
        <!--        </div>-->
      </el-form>
    </div>
    <input-dialog ref="inputDialog" @submit="confirm($event, 'inputDialog')" />
    <RobotsDialog
      @addRobot="addRobot"
      @editRobot="editRobot"
      :currentRow="currentRow"
      v-model="openRobots"
    ></RobotsDialog>
    <fixed-action-bar>
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="saveConfig('config')"
        >保存</el-button
      >
    </fixed-action-bar>
  </div>
</template>

<script>
import {
  getConfig,
  saveConfig,
  getMessagesConfig,
} from "../api/wx-company-config";
import FixedActionBar from "@/base/layout/FixedActionBar";
import InputDialog from "../components/InputDialog";
import RobotsDialog from "../components/RobotsDialog";
export default {
  components: { InputDialog, FixedActionBar, RobotsDialog },
  data() {
    var checkIsSet = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写客户联系 Secret"));
      }
      callback();
    };
    // var checkIsSetApp = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('请填写应用 Secret'));
    //   }
    //   callback();
    // };
    return {
      loading: false,
      saveLoading: false,
      config: null,
      openRobots: false,
      thead: [
        { label: "机器人名字", prop: "name", width: 150 },
        { label: "Webhook地址", prop: "webhook" },
        { label: "推送消息", prop: "admin_user_messages", width: 80 },
      ],
      rules: {
        // corp_id: [
        //   { required: true, message: "请填写企业 ID", trigger: "blur" },
        // ],
        // is_set_contact_corp_secret: [
        //   { required: true, message: "请填写客户联系 Secret", trigger: "blur" },
        //   { validator: checkIsSet, trigger: "blur" },
        // ],
        // app_agentid: [{required: true, message: '请填写应用 ID', trigger: 'blur'}],
        // is_set_app_secret: [
        //   {required: true, message: '请填写应用 Secret', trigger: 'blur'},
        //   {validator: checkIsSetApp, trigger: 'blur'}
        // ]
      },
      currentRow: {},
      // 工作台应用规则
      messagesConfig: [],
    };
  },
  created() {
    this.getConfig();
    this.getMsgConfig();
  },
  methods: {
    confirm(data, source) {
      if (source === "appDialog") {
        this.config.app_secret = data.app_secret;
        this.config.is_set_app_secret = 1;
        this.$refs["config"].clearValidate("is_set_app_secret");
      } else {
        this.config.contact_corp_secret = data.app_secret;
        this.config.is_set_contact_corp_secret = 1;
        this.$refs["config"].clearValidate("is_set_contact_corp_secret");
      }

      this.close(source);
    },
    close(source) {
      this.$refs[source].close();
    },
    showInputDialog(title, source) {
      this.$refs[source].open({
        title,
        label: source === "appDialog" ? "应用 Secret" : "客户联系 Secret",
        message:
          source === "appDialog"
            ? "请填写应用 Secret"
            : "请填写客户联系 Secret",
      });
    },
    getConfig() {
      this.loading = true;
      getConfig()
        .then((res) => {
          this.config = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    saveConfig(keyword) {
      this.$refs[keyword].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          let postData = JSON.parse(JSON.stringify(this.config));
          postData.robots.forEach(item => {
            item.admin_user_messages =  item.admin_user_messages.map(item => item.msg)
          })
          saveConfig(this.config)
            .then((res) => {
              this.$message.success(res.msg);
              this.getConfig();
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 打开添加机器人弹窗
    addRobotDialog() {
      this.currentRow = {};
      this.openRobots = true;
    },
    // 点击移除
    DeleteRow(i) {
      this.config.robots.splice(i, 1);
    },
    // 修改机器人
    EditRow(i) {
      let row = JSON.parse(JSON.stringify(this.config.robots[i])); 
      // 需要特殊处理一下，适配多选选择器回显
      row.admin_user_messages = row.admin_user_messages.map((item) => {
        return item.msg;
      });
      this.currentRow = row;
      this.currentI = i;
      this.openRobots = true;
    },
    // 添加群机器人
    addRobot(robot) {
      let msgArr = [];
      // 适配多选
      // 根据 msg 字段 找到 对应的title
      robot.admin_user_messages.forEach((element) => {
        this.messagesConfig.filter((item) => {
          if (element === item.msg) {
            msgArr.push(item);
          }
        });
      });
      console.log(msgArr);
      robot.admin_user_messages = msgArr;
      this.config.robots.push(robot);
    },
    // 编辑群机器人
    editRobot(robot) {
      let msgArr = [];
      // 适配多选
      // 根据 msg 字段 找到 对应的title
      robot.admin_user_messages.forEach((element) => {
        this.messagesConfig.filter((item) => {
          if (element === item.msg) {
            msgArr.push(item);
          }
        });
      });
    
      robot.admin_user_messages = msgArr;
      console.log(msgArr);

      this.$set(this.config.robots, this.currentI, robot);
    },
    // 获取消息配置
    getMsgConfig() {
      getMessagesConfig().then((res) => {
        this.messagesConfig = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list-content {
  min-height: 200px;
}

a {
  text-decoration: none;
  color: #3576ff;
}

.list-form + .list-form {
  margin-top: 20px;
}

.list-form {
  position: relative;
  margin-top: 10px;
  width: 530px;
  padding: 20px;
  background-color: #f9f9f9;

  .del-btn {
    position: absolute;
    top: 0px;
    right: 20px;
    font-size: 14px;
    color: #3576ff;
    z-index: 2;
  }
}

.add-btn {
  width: 530px;
  margin-top: 20px;
  border: 1px dashed #dcdfe6;

  &:hover {
    border: 1px dashed #3576ff;
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
    color: #606266;
  }
}

.tips {
  margin-top: 8px;
  font-size: 14px;
  color: #c0c4cc;
  display: flex;
  align-items: center;
  i {
    margin-right: 5px;
  }
}
.tab-content {
  margin-top: 8px;

  padding: 10px;
  border-radius: 10px;
  background-color: #f2f2f2;
}
</style>
